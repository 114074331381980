@media print {
    * {
      color: black !important;
      background-color: white !important; /* Agar kisi element ki background color ho to use white kardo */
      border-color: black !important;
    }
  
    input, textarea {
      color: black !important; /* Input fields ke liye text color black ho */
      background-color: white !important; /* Input fields ke liye background white ho */
    }
  
    ::placeholder {
      color: black !important; /* Placeholder ke liye lightgrey color */
    }
  }