/*--------------------------------

loginpage

-------------------------------- */

.auth-page {
  background: #d60b11;
  background: url(../img/login-bg.jpg) no-repeat top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}
.auth-page .container{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
}
 .form-signin {
  overflow: hidden;
  width: 100%;
  max-width: 330px;
  background: #fafafa;
  -webkit-border-radius:20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.signin-field{
  padding: 12px 35px 25px 35px;
}
.signin-image{
  background: #ffffff;
  border-radius: 0 0 137% 26%;
  width: 173px;
  height: 159px;
  box-shadow: 0 0px 12px rgb(0 0 0 / 5%);
}
.signin-image .logo{
  padding: 8px;
}
.form-signin label{
  font-weight: 500;
  font-size: 13px;
  color: #6c6c6c;
}
.form-signin .form-control{
  font-size: 12px;
  font-weight: 400;
}

@media (max-width: 480px) {
  .auth-page .container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
  }
}
.input-group-btn{
  position: relative;
}

.btn_eye_icon{
  position: absolute;
   top: 27px ;
   right:5px;
   color: #d60b11;
 }

.signin-field .email{
 margin-left: 3px;
 margin-top: 2px;
}

.signin-field .phone{
margin-left: -15px;
margin-top: 3px;
}


.forget{
  font-size: 12px;
}

.navbar {
  height: auto!important;
  margin-bottom: 0;
  min-height: 53px;
  padding-bottom: 0;
  padding-top: 0;
}

.navbar-custom {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #fff;
  /* border-bottom: 3px solid #d60b11; */
  border-bottom: 1px solid #000;

}

.navbar a {
  color: #000 !important;
  padding: 0 10px;
}

.navbar .main-menu .dropdown .dropdown-menu {
  padding-top: 10px;
  padding-bottom: 10px;
}

 .navbar .main-menu .dropdown-menu .dropdown-item:first-child {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.navbar .main-menu .dropdown-menu .dropdown-item:last-child {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.navbar .main-menu .dropdown .dropdown-menu a {
  padding: 5px 10px;
}

.navbar .main-menu .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.2em solid;
  border-right: 0.2em solid transparent;
  border-bottom: 0;
  border-left: 0.2em solid transparent;
}
 .navbar-custom .navbar-brand {
  height: 94px!important;
  margin: 0;
  padding-bottom: 0.19rem;
  padding-top: 0.19rem;
}

@media (max-width: 767px){
.navbar .navbar-toggler .navbar-toggler-icon {
    border: 1px solid #897878;
    border-radius: 5px;
    position: relative;
 }
}

@media (max-width: 767px){
.navbar .navbar-toggler {
    height: auto;
    width: auto;
  }
}

@media (max-width: 767px){
.navbar .navbar-toggler .navbar-toggler-icon:before {
    color: #25212191;
    content: "\f036";
    font-family: FontAwesome;
    font-weight: 100;
    left: 4px;
    position: absolute;
    top: 5px;
  }
}

section.section.main-footer {
  background: #f7f7f7;
  padding: 30px 0;
}

h4.footer-title {
  margin-bottom: 10px;
  
}

.bank-information h4 {
  font-size: 21px;
  font-weight: 600; 
  margin-top: 0px;
} 


/* .h4, h4 {
  font-size: 1.714em;
  line-height: 1.45em;
  margin-bottom: 15px;
  margin-top: 0;
} */

ul.footer-list li {
  list-style: none;
  font-family: Calibri;
  font-size: 14px;
}

ul.footer-list li a {
  color: #3f3c3c; 
  font-size: 14px;
}
ul.footer-list {
  line-height: 25px;
}
ul {
  margin: 0;
  padding-left: 0;
}
/* .followus .footer-list li:first-child {
  line-height: 23px;
  padding-left: 30px;
} */
.followus .footer-list li {
  line-height: 40px;
  position: relative;
}
/* .followus .footer-list li:first-child:before {
  content: "\f015";
  top: 0;
} */

.followus .footer-list li:before {
  /* color: #d60b11; */
  color: #000;
  font-family: FontAwesome;
  font-size: 22px;
  left: 0;
  position: absolute;
}
.followus .footer-list li i {
  background: black;
  color: #fff;
  line-height: normal;
  border-radius: 50%;
  width: 30px;
  text-align: center;
  height: 30px;
  padding: 7px 0;
  -webkit-transition: ease 0.3s;
  -o-transition: ease 0.3s;
  transition: ease 0.3s;
  margin-right: 10px;
}
/* .followus .footer-list li:nth-child(2):before {
  content: "\f0e0";
  font-family: FontAwesome;
  top: 0;
}
.followus .footer-list li:nth-child(3):before {
  content: "\f095";
  font-size: 20px;
  top: 0;
} */
/* .followus .footer-list li:nth-child(2) {
  padding-left: 30px;
} */
/* .followus .footer-list li:nth-child(3):before {
  content: "\f095";
  font-size: 20px;
  top: 0;
} */
/* .followus .footer-list li:nth-child(3) {
  padding-left: 30px;
} */
ul.social-icons li {
  display: inline-block;
  margin-right: 23px;
}

ul.social-icons li a {
  color: #3f3c3c; 
  font-size: 14px;
}
.questions a {
  color: #3f3c3c;
  font-size: 15px;
  
}

h4.footer-title {
  margin-bottom: 10px;
  font-size: 21px;
  font-weight: 600; 
  margin-top: 0px;
}

.questions {
  margin-top: 10px; 
  font-size: 14px;
}

.footer-copyright p {
  margin-bottom: 0;
  font-size: 14px;
}
.footer-copyright {
  /* color: #ffffff; */
  color: #3f3c3c;
  padding: 10px 0px;
  /* background-color: #d60b11; */
  background: #f7f7f7;

}
.btn_eye_icon-reset{
  position: absolute;
   top: 34px ;
   right:7px;
   color: #d60b11;
 }
ul {
  margin: 0;
  padding-left: 0;
}
 
.navbar .main-menu .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.2em solid;
  border-right: 0.2em solid transparent;
  border-bottom: 0;
  border-left: 0.2em solid transparent;
}
.navbar a {
  color: #000 !important;
  padding: 0 10px;
}
.navbar .main-menu .dropdown .dropdown-menu {
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar .main-menu .dropdown-menu .dropdown-item:first-child {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.navbar .main-menu .dropdown .dropdown-menu a {
  padding: 5px 10px;
}
.menu-drop {
  border: 0;
  border-radius: 0;
  margin: 0;
  min-width: 450px;
  position: absolute;
  left: 0;
}
.navbar .main-menu .dropdown .dropdown-menu {
    padding-top: 10px;
    padding-bottom: 10px;
}
.menu-drop .col div {
    width: 100%;
    border-radius: 0 !important;
    /* background: #262627; */
    background: #ffffff;
    color: #2c2c2c;
    padding: 0 8px;
}
.navbar .main-menu .dropdown-menu .dropdown-item:first-child {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.navbar .main-menu .dropdown .dropdown-menu a {
  padding: 5px 10px;
}
.menu-drop .col div button:hover {
  color: #000 !important;
  background: #fff0 !important;
  text-decoration: underline !important;
}
.menu-drop .col div button:hover {
  background: #fff0 !important;
}
.menu-drop .col div.dropdown-custom button {
  border-radius: 0 !important;
  padding: 10px 20px;
  border-radius: 0 !important;
}
.menu-drop .col:last-child>div {
  border-left: 1px solid #8f8e8e;
}
.maincontent .menu-drop .col:last-child>div {
  border-left: 1px solid #8f8e8e;
}
.menu-drop .col div {
  width: 100%;
  border-radius: 0 !important;
  /* background: #262627; */
  background: #ffffff;
  color: #2c2c2c;
  padding: 0 8px;
}
.menu-drop .col div {
  width: 100%;
  border-radius: 0 !important;
  /* background: #262627; */
  background: #ffffff;
  color: #2c2c2c;
  padding: 0 8px;
}
 .inner-dropdown {
  display: flex;
}
.menu-drop .dropdown-divider {
  border-color: #8f8e8e;
}
.navbar .main-menu a {
  font-size: 16px !important;
  font-family: Calibri !important;
}

.second-menu,  .dropdown-custom {
  min-height: 275px;
}

.child-menu button {
  padding: 5px 0px !important;
}

/* h4 {
  font-size: 21px;
  font-weight: 600;
} */

 
.btn-primary {
  font-size: 16px !important;
  text-transform: capitalize;
  border-radius: 6px;
  cursor: pointer !important;
}


a.waBtn {
  padding: 9px;
  font-size: 16px !important;
}

.topbar-list {
  display: flex;
  list-style-type: none;
  justify-content: start;
  margin-left: 10px;
}
.topbar-list li span a {
  color: #000 !important;
  font-size: 16px;
  margin-left: 3px;
}

body{
  font-family: Calibri !important;
  font-size: 14px !important;
}
@media (max-width: 767px){
.collapse.show.navbar-collapse .main-menu {
    justify-content: center;
  }
}

