.card{
  border-radius: $border-radius-x-large;
  box-shadow: 0 5px 5px -4px rgba(0, 0, 0, 0.15);
  background-color: #FFFFFF;
  color: $card-black-color;
  margin-bottom: 20px;
  position: relative;
  border: 0 none;

  -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;

    .card-body{
        padding: 15px 15px 10px 15px;

        &.table-full-width{
            padding-left: 0;
            padding-right: 0;
        }
    }

    .card-header{
      &:not([data-background-color]){
        background-color: #d60b11;
      }
      color: #fff;
      font-weight: 600;
      padding: 10px 15px 10px;
      border: 0;

      .card-title{
          margin-top: 10px;
      }
    }

    .map{
        border-radius: $border-radius-small;

        &.map-big{
          height: 400px;
        }
    }

    &[data-background-color="orange"]{
        background-color: $primary-color;

        .card-header{
            background-color: $primary-color;
        }

        .card-footer{
            .stats{
                color: $white-color;
            }
        }
    }

    &[data-background-color="red"]{
        background-color: $danger-color;
    }

    &[data-background-color="yellow"]{
        background-color: $warning-color;
    }

    &[data-background-color="blue"]{
        background-color: $info-color;
    }

    &[data-background-color="green"]{
        background-color: $success-color;
    }

    

    .image{
        overflow: hidden;
        height: 200px;
        position: relative;
    }

    .avatar{
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 15px;
    }

    .numbers {
      font-size: 2em;
    }

    .big-title {
      font-size: 12px;
      text-align: center;
      font-weight: 500;
      padding-bottom: 15px;
    }

    label{
        font-size: $font-size-base;
        margin-bottom: 2px;
        color: $primary-color;
    }

    .card-footer{
        background-color: transparent;
        border: 0;


        .stats{
            i{
                margin-right: 5px;
                position: relative;
                top: 0px;
                color: $default-color;
            }
        }

        .btn{
            margin: 0;
        }
    }

    &.card-plain{
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;


        .card-body{
            padding-left: 5px;
            padding-right: 5px;
        }

        img{
            border-radius: $border-radius-extreme;
        }
    }
}
.card .card{
    box-shadow: none !important;
  }
  .card .card-body .card-body{
    background: #fffcfc;
  }
.nav-tabs {
    margin-bottom: 10px;
    border-bottom: 1px solid #dddddd;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:hover{
    color: #ffffff;
    background-color: #d60b11;
    border-color: #d60b11 #d60b11 #d60b11 !important;
}
.nav-tabs .nav-item {
    margin-left: 4px !important;
}
.nav-tabs li.nav-item:first-child {
    margin-left: 0px !important;
}
.nav-tabs .nav-link, .nav-tabs .nav-item.show .nav-link {
    cursor: pointer !important;
    background-color: #fdfdfd;
    border-color: #f7d1d2 #f7d1d2 #dddddd !important;
}
  .nav-tabs li a{
    display: block !important;
    padding: 0.5rem 1rem !important;
  }
  .inputview-data{
    display: block;
    background: #f7f7f7;
    padding: 6px;
    }

.tab-pane .nav-tabs a.active, .tab-pane .nav-tabs li.show a, .tab-pane .nav-tabs a:hover {
    color: #ffffff;
    background-color: #d60b11;
    border: 1px solid transparent;
    border-color: #d60b11 #d60b11 #d60b11 !important;
    text-decoration: none !important;
}
.tab-pane .nav-tabs li {
    margin-left: 4px !important;
}
.tab-pane .nav-tabs a, .tab-pane .nav-tabs li.show a {
    cursor: pointer !important;
    background-color: #fdfdfd;
    border: 1px solid transparent;
    border-color: #f7d1d2 #f7d1d2 #dddddd !important;
    text-decoration: none !important;
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

.modal-header {
    padding: 0.5rem 1rem;
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 1.37em;
}
.modal-footer {
    padding: 0.55rem;
}
label {
    font-size: $font-size-base;
    margin-bottom: 2px;
    color: $primary-color;
}