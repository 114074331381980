.table {

    .img-wrapper {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
    }

    .img-row {
        max-width: 60px;
        width: 60px;
    }

    .form-check {
        margin: 0;

        & label .form-check-sign::before,
        & label .form-check-sign::after {
            top: -17px;
            left: 4px;
        }
    }

    .btn {
        margin: 2px;
    }

    .btn-circle {
        width: 30px;
        height: 30px;
        text-align: center;
        padding: 6px 0;
        font-size: 12px;
        line-height: 1.428571429;
        border-radius: 15px;
    }

    small, .small {
        font-weight: 300;
    }

    .card-tasks .card-body & {
        margin-bottom: 0;

        > thead > tr > th,
        > tbody > tr > th,
        > tfoot > tr > th,
        > thead > tr > td,
        > tbody > tr > td,
        > tfoot > tr > td {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    > thead > tr > th {
        font-size: 12px;
        font-weight: $font-weight-bold;
        padding-bottom: 0;
        background: #fff;
        /*text-transform: uppercase;*/
        /*border: 0;*/
    }

    .radio,
    .checkbox {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        width: 15px;

        .icons {
            position: relative;
        }

        label {
            &:after,
            &:before {
                top: -17px;
                left: -3px;
            }
        }
    }

    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th {
        padding: 8px 6px;
        vertical-align: middle;
    }

    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td {
        padding: 6px 6px;
        vertical-align: middle;
    }

    .th-description {
        max-width: 150px;
    }

    .td-price {
        font-size: 26px;
        font-weight: $font-weight-light;
        margin-top: 5px;
        position: relative;
        top: 4px;
        text-align: right;
    }

    .td-total {
        font-weight: $font-weight-bold;
        font-size: $font-size-h5;
        padding-top: 20px;
        text-align: right;
    }

    .td-actions .btn {
        margin: 0px;
    }

    > tbody > tr {
        position: relative;
    }
}

.table-shopping{
    > thead > tr > th{
        font-size: $font-size-h6;
        text-transform: uppercase;
    }
    > tbody > tr > td{
        font-size: $font-paragraph;

        b{
            display: block;
            margin-bottom: 5px;
        }
    }
    .td-name{
        font-weight: $font-weight-normal;
        font-size: 1.5em;
        small{
            color: $dark-gray;
            font-size: 0.75em;
            font-weight: $font-weight-light;
        }
    }
    .td-number{
       font-weight: $font-weight-light;
       font-size: $font-size-h4;
   }
    .td-name{
        min-width: 200px;
    }
    .td-number{
        text-align: right;
        min-width: 170px;

        small{
            margin-right: 3px;
        }
    }

    .img-container{
        width: 120px;
        max-height: 160px;
        overflow: hidden;
        display: block;

        img{
            width: 100%;
        }
    }
}

.table-responsive{
  /*overflow: scroll;*/
  overflow-x:auto;
  padding-bottom: 10px;
}

#tables .table-responsive{
    margin-bottom: 30px;
}

.table-hover>tbody>tr:hover{
  background-color: #f5f5f5;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.02);
}